/* eslint-disable import/no-anonymous-default-export */
import node from "../assets/node.webp";
import email from "../assets/email.webp";
import gmail from "../assets/gmail.png";
import logo from "../assets/logo.png";
import logo1 from "../assets/logo.jpg";
import phone from "../assets/phone.png";
import mobile from "../assets/mobile.webp";
import circle from "../assets/circle.svg";
import userlogo from "../assets/userlogo.svg"
import react from "../assets/react.webp";
import redux from "../assets/redux.webp";
import pic2 from "../assets/pic2.webp";
import mainheader from "../assets/mainheader.png"
export default {
  mainheader,
  email,
  mobile,
  react,
  redux,
  circle,
  node,
  userlogo,
  pic2,
  gmail,
  phone,
  logo,
  logo1
};
