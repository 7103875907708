import React from "react";
import { images } from "../../constants";
import { motion } from "framer-motion";
import "./Header.scss";
import { AppWrap } from '../../wrapper'


// const scaleVariants = {
//   whileInView: {
//     scale: [0, 1],
//     opacity: [0, 1],
//     transition: {
//       duration: 1,
//       ease: "easeInOut",
//     }
//   }
// };

const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello, I am</p>
              <h1 className="head-text">Ojasva Jain</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">
            A Graduate Computer Science Engineer(2024) from IIIT Kota with a passion for logical and critical thinking. I have a deep love for mathematics finance and trading . I am a tech enthusiast.
In addition to my academic achievements, I am a grand master in UCMAS, showcasing my mental arithmetic skills. I have also excelled in competitive exams, with a JEE Main percentile of 98.3% and qualification in JEE Advanced. Furthermore, I have successfully cleared GATE 2023 and during my college years, I maintained an impressive 8.66 CGPA and was Public Relations Head of IIIT Kota . Moreover, I possess strong communication skills and excel in working within a team.
            </p>
            {/* <p className="p-text">Web Developer</p> */}
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.mainheader} alt="profile" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="circle"
          className="overlay_circle"
        />
      </motion.div>

      {/* <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.node, images.react, images.redux].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <motion.img
              src={circle}
              alt="circle"
              animate={{ rotate: [0, 360] }}
              transition={{ repeat: Infinity, duration: 2.5 }}
            />
          </div>
        ))}
      </motion.div> */}
    </div>
  );
};

export default AppWrap(Header, "home");
